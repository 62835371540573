<!-- 在线下单 -->
<template>
    <div>
        <goodListTemp/>
    </div>
</template>

<script>
import goodListTemp from '@/components/goodListTemp'
export default {
    name: 'PlaceOrder',
    components: { goodListTemp },
    
    data() {
        return {

        };
    },

    created() {
        
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>

</style>